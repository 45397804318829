<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      data: {},
      teachers: [],
      semestreOtions: [],
      courses: [],
      course: {
        nom_cours:"",
        type_ue: null,
        professeur_id: null,
        credits: "",
      },
      searchQuery: null,
      filiere: {},
      professor: {},
      submited: false,
      spanView: false,
      page: 1,
      perPage: 5,
      pages: [],
      title: "Planing Cours",
      items: [
        {
          text: "Cours",
          href: "/",
        },
        {
          text: "Planification",
          active: true,
        },
      ],
    };
  },

  validations: {
    data: {
      nom_cours: {
        required: helpers.withMessage(
          "Veuillez saisir le nom du cours",
          required
        ),
      },
      credits: {
        required: helpers.withMessage(
          "Veuillez saisir le nombre de credits",
          required
        ),
      },
      type_ue: {
        required: helpers.withMessage(
          "Veuillez choisir le type d'UE",
          required
        ),
      },
      semestre: {
        required: helpers.withMessage("Veuillez choisir le semestre", required),
      },
      type_cours: {
        required: helpers.withMessage(
          "Veuillez choisir le type de cours",
          required
        ),
      },
      professeur_id: {
        required: helpers.withMessage(
          "Veuillez Choisir le responsable du cours",
          required
        ),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mounted() {
    this.v$.data.$touch();
    this.decryptFiliere();
    this.getTeachers();
    this.getFacultyCourses();
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.courses);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
            data.nom_cours?.toLowerCase().includes(search) ||
            data.type_ue?.toLowerCase().includes(search) ||
            data.professor?.nom.toLowerCase().includes(search) ||
            data.professor?.prenom.toLowerCase().includes(search) 
          );
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    initCreation() {
      this.submited = true;

      this.createMatter();
    },

    createMatter() {
      this.data.filiere_id = this.filiere?.id;
      this.spanView = true;
      const self = this;
      this.v$.data.$touch();
      this.$store
        .dispatch("postRequest", { route: "api/courses", data: this.data })
        .then(
          function (response) {
            self.getFacultyCourses();
            
            self.spanView = false;
            Swal.fire(response.data.message, "Cours enregistrer!", "success");
          },
          function (error) {
            if (error !== null) {
              self.spanView = false;
              Swal.fire({
                title: "Oops...",
                text: "Il y a un problème!",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          }
        );
    },

    getFacultyCourses() {
      const self = this;
      this.$store
        .dispatch("getRequest", {
          route: "/api/getFacultyCourses/" + this.filiere?.id,
          data: this.data,
        })
        .then(
          function (response) {
            self.courses = response.data;
            self.setPages();
          },
          function (error) {
            if (error !== null) {
              console.log("error");
            }
          }
        );
    },

    getCourse(data) {
      this.course = {
        id: data.id,
        nom_cours: data.nom_cours,
        type_ue: data.type_ue,
        professeur_id: data.professeur_id,
        credits: data.credits,
      };
    },
    async deleteCourse(id) {
      const self = this;
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cette matière ? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-primary w-xs mt-2",
        cancelButtonClass: "btn btn-danger w-xs mt-2",
        confirmButtonText: "supprimer!",
      }).then(async (result) => {
        if (result.value == 1) {
          this.$store
            .dispatch("deleteRequest", {
              route: `api/courses/${id}`,
              data: this.data,
            })
            .then(
              function (response) {
                self.getFacultyCourses();
                console.log(response);
                Swal.fire({
                  title: "Supprimer",
                  text: "matière supprimée avec succès!",
                  icon: "success",
                });
              },
              function (error) {
                Swal.fire({
                  title: "Oops...",
                  text: error,
                  icon: "error",
                  confirmButtonClass: "btn btn-primary w-xs mt-2",
                  buttonsStyling: false,
                  showCloseButton: true,
                });
              }
            );
        }
      });
    },

    decryptFiliere() {
      const encryptedMessage1 = localStorage.getItem("dataF");

      if (!encryptedMessage1) {
        console.error('Aucune donnée trouvée pour "dataF" dans localStorage.');
        return;
      }

      const encryptionKey1 = "Slim#9065";

      try {
        const decrypted1 = CryptoJS.AES.decrypt(
          encryptedMessage1,
          encryptionKey1
        );
        const decryptedMessage1 = decrypted1.toString(CryptoJS.enc.Utf8);

        if (!decryptedMessage1) {
          console.error(
            "Erreur lors du décryptage : le message décrypté est vide ou invalide."
          );
          return;
        }
        this.data.school_year = localStorage.getItem("selectedYear");
        this.filiere = JSON.parse(decryptedMessage1);
        this.data.credits = this.filiere.full_classe?.level === 'primaire' ? 1 : null; 
        this.professor = this.filiere.department_professors_history[0]?.professor;
        this.data.professeur_id = this.filiere.full_classe?.level === 'primaire' ? this.professor.id : null; 
        this.professor.full_name = this.professor.nom +' '+this.professor.prenom;
        
        if (this.filiere.annee_formation == 1) {
          this.semestreOtions = [
            { value: 1, label: "1" },
            { value: 2, label: "2" },
          ];
        } else if (this.filiere.annee_formation == 2) {
          this.semestreOtions = [
            { value: 3, label: "3" },
            { value: 4, label: "4" },
          ];
        } else if (this.filiere.annee_formation == 3) {
          this.semestreOtions = [
            { value: 5, label: "5" },
            { value: 6, label: "6" },
          ];
        }
      } catch (error) {
        console.error("Erreur lors du décryptage des données :", error);
      }
    },

    getTeachers() {
      const self = this;
      this.$store
        .dispatch("getRequest", {
          route: "api/user/professeur ",
          data: this.data,
        })
        .then(
          function (response) {
            response.data.searchedUsers.forEach((element) => {
              self.teachers.push({
                value: element.id,
                label: element.nom + " " + element.prenom,
              });
            });
          },
          function (error) {
            console.log(error);
          }
        );
    },
    initUpdate() {
      this.submited = true;
      this.updateCourse();
    },
    updateCourse() {
      this.spanView = true;
      const self = this;
      this.$store
        .dispatch("putRequest", {
          route: `api/courses/${this.course.id}`,
          data: this.course,
        })
        .then(
          function (response) {
            self.spanView = false;
            self.getFacultyCourses();
            Swal.fire(
              response.data.message,
              "Modification éffectuer avec succès!",
              "success"
            );
          },
          function (error) {
            if (error !== null) {
              self.spanView = false;
              Swal.fire({
                title: "Oops...",
                text: "Il y a un problème!",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          }
        );
    },

    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.courses.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="row justify-content-between mb-3">
            <div class="col-md-4 mb-3">
              <input type="text" class="form-control" v-model="searchQuery" placeholder="Search..." />
            </div>
            <div class="col-md-6 mb-3 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target=".bs-example-modal-lg"
              >
                <i class="mdi mdi-plus"></i>
                Matière
              </button>
            </div>
          </div>

          <div class="table-responsive table-card">
            <h4>Programme de cours de {{ filiere.full_classe?.name }} {{ filiere.group?.toUpperCase() }}</h4>
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead class="table-light text-muted">
                <tr>
                  <th scope="col">Matière</th>

                  <th scope="col" v-if="filiere.full_classe?.level !='primaire'">Type d'UE</th>

                  <th scope="col">Professeur</th>

                  <th scope="col" v-if="filiere.full_classe?.level !='primaire'">Coeffiscient</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <!--end thead-->
              <tbody class="list form-check-all">
                <tr v-for="(data, index) of resultQuery" :key="index">
                  <td class="id">{{ data.nom_cours }}</td>
                  <td v-if="filiere.full_classe?.level !='primaire'">{{ data.type_ue }}</td>
                  <td class="pairs">{{ data.professor?.nom }} {{ data.professor?.prenom }}</td>
                  <td v-if="filiere.full_classe?.level !='primaire'">{{ data.credits }}</td>
                  <td>
                    <div class="hstack gap-3 flex-wrap">
                      <a
                        href="javascript:void(0);"
                        class="link-success fs-15"
                        data-bs-toggle="modal"
                        data-bs-target=".bs-update-modal"
                        @click="getCourse(data)"
                      >
                        <i class="ri-edit-2-line"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        class="link-danger fs-15"
                        @click="deleteCourse(data.id)"
                      >
                        <i class="ri-delete-bin-line"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!--end tbody-->
            </table>
          </div>

          <div class="d-flex justify-content-end mt-3">
            <div class="pagination-wrap hstack gap-2">
              <a
                class="page-item pagination-prev disabled"
                href="#"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </a>
              <ul class="pagination listjs-pagination mb-0">
                <li
                  :class="{
                    active: pageNumber == page,
                    disabled: pageNumber == '...',
                  }"
                  v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                  :key="index"
                  @click="page = pageNumber"
                >
                  <a class="page" href="#">{{ pageNumber }}</a>
                </li>
              </ul>
              <a
                class="page-item pagination-next"
                href="#"
                @click="page++"
                v-if="page < pages.length"
              >
                Next
              </a>
            </div>
          </div>
        </div>
        <div
          class="modal fade bs-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <form @submit.prevent="initCreation">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="myLargeModalLabel">
                    Matière
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="course" class="form-label">Matière</label>
                        <input
                          type="text"
                          id="course"
                          class="form-control"
                          v-model="data.nom_cours"
                        />
                        <div
                          v-for="(item, index) in v$.data.nom_cours.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.nom_cours) ||
                              (v$.data.nom_cours.$error && soumis)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col  -->
                    <div class="col-lg-12" v-if="filiere.full_classe?.level !='primaire'">
                      <div class="mb-3">
                        <label for="typeUE" class="form-label">Type de matiere</label>
                        <Multiselect
                          class="form-control"
                          v-model="data.type_ue"
                          :class="{
                            'is-invalid':
                              (v$.data.type_ue.$error && data.type_ue) ||
                              (v$.data.type_ue.$error && soumis),
                          }"
                          :close-on-select="true"
                          :searchable="false"
                          :show-labels="false"
                          :options="[
                            { 
                            value: 'Obligatoire', 
                            label: 'Obligatoire' 
                            },
                            {
                              value: 'Facultative',
                              label: 'Facultative',
                            },
                          ]"
                        />
                        <div
                          v-for="(item, index) in v$.data.type_ue.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.type_ue) ||
                              (v$.data.type_ue.$error && soumis)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="professeur" class="form-label"
                          >Professeur Responsable</label
                        >

                        <Multiselect
                          class="form-control"
                          v-model="data.professeur_id"
                          :class="{
                            'is-invalid':
                              (v$.data.professeur_id.$error &&
                                data.professeur_id) ||
                              (v$.data.professeur_id.$error && soumis),
                          }"
                          :close-on-select="true"
                          :searchable="false"
                          :show-labels="false"
                          :options="teachers"

                        />
                        <div
                          v-for="(item, index) in v$.data.professeur_id.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.professeur_id) ||
                              (v$.data.professeur_id.$error && soumis)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="credits" class="form-label">Coéffiscient</label>
                        <input
                          type="number"
                          class="form-control"
                          id="credits"
                          :readonly="filiere.full_classe && filiere.full_classe.level === 'primaire'"
                          v-model="data.credits"
                          :class="{
                            'is-invalid': (v$.data.credits.$error && soumis),
                          }"
                          placeholder="Coéffiscient"
                        />
                        <div
                          v-for="(item, index) in v$.data.credits.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.credits) ||
                              (v$.data.credits.$error && soumis)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>
                <div class="modal-footer">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-link link-success fw-medium"
                    data-bs-dismiss="modal"
                  >
                    <i class="ri-close-line me-1 align-middle"></i> Close
                  </a>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="spanView"
                  >
                    <span
                      v-if="spanView"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
        <div
          class="modal fade bs-update-modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <form @submit.prevent="initUpdate">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="myLargeModalLabel">
                    Modifier Cours
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="course" class="form-label">Matière</label>
                        <input
                          type="text"
                          id="course"
                          class="form-control"
                          v-model="course.nom_cours"
                        />
                      </div>
                    </div>
                    <!--end col  -->
                    <div class="col-lg-12" v-if="filiere.full_classe?.level !='primaire'">
                      <div class="mb-3">
                        <label for="typeUE" class="form-label">Type de matiere</label>
                        <Multiselect
                          class="form-control"
                          v-model="course.type_ue"
                          :close-on-select="true"
                          :searchable="false"
                          :show-labels="false"
                          :options="[
                            { 
                            value: 'Obligatoire', 
                            label: 'Obligatoire' 
                            },
                            {
                              value: 'Facultative',
                              label: 'Facultative',
                            },
                          ]"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="professeur" class="form-label"
                          >Professeur Responsable</label
                        >
                        <Multiselect
                          class="form-control"
                          v-model="course.professeur_id"
                          :close-on-select="true"
                          :searchable="false"
                          :show-labels="false"
                          :options="teachers"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="credits" class="form-label">Coéffiscient</label>
                        <input
                          type="number"
                          class="form-control"
                          id="credits"
                          :readonly="filiere.full_classe && filiere.full_classe.level === 'primaire'"
                          v-model="course.credits"
                          placeholder="Coéffiscient"
                        />
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                </div>
                <div class="modal-footer">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-link link-success fw-medium"
                    data-bs-dismiss="modal"
                  >
                    <i class="ri-close-line me-1 align-middle"></i> Close
                  </a>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="spanView"
                  >
                    <span
                      v-if="spanView"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Modifier
                  </button>
                </div>
              </div>
            </form>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
      </div>
    </div>
  </Layout>
</template>
